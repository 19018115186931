/* ChatPortfolio.css */
.chat-portfolio {
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .chat-portfolio h1 {
    margin-bottom: 20px;
  }
  
  .chat-container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  