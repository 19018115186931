.chat-window {
    width: 100%;
    max-width: 700px;
    overflow-y: auto;
    overflow-x: auto; /* Prevent horizontal scrolling */
    margin: 0 auto; /* Center align the chat window */
    flex: 1; /* Make the chat window take up available space */
}

.chat-input-form {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 10px;
    background-color: #fff;
    border-top: 1px solid #ddd;
    flex-shrink: 0; /* Prevent the input form from shrinking */
}

.chat-input-space {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chat-textarea {
    width: 100%; /* Ensure the textarea takes up full width */
    max-width: 600px; /* Set the maximum width */
    resize: none;
    overflow: hidden;
    border-radius: 20px; /* Make the textarea more rounded */
    padding: 10px;
    flex: 1
}

.chat-box {
    width: 100%; /* Ensure the chat box takes the full width */
    max-width: 600px; /* Set the maximum width */
    word-wrap: break-word; /* Ensure long words break to avoid overflow */
    margin: 0 auto; /* Center align the chat box */
    flex: 1; /* Make the chat box take up available space */
}

.user-message h3 {
    margin: 0;
    padding: 10px 0;
    font-size: 1.5em;
    border-bottom: 1px solid #ddd; /* Add a line underneath */
}

.user-message hr {
    display: none;
}

.message-content {
    word-wrap: break-word; /* Ensure long words break to avoid overflow */
    white-space: pre-wrap; /* Preserve whitespace and wrap text as needed */
}

.dynamic-content table {
    width: 100%;
    max-width: 800px;
    border-collapse: collapse;
    text-align: left;
}

.dynamic-content th, .dynamic-content td {
    padding: 12px 15px;
    border: 1px solid #ddd;
}

.dynamic-content thead {
    background-color: #009879;
    color: #ffffff;
}

.dynamic-content tbody tr:nth-child(even) {
    background-color: #f3f3f3;
}

.dynamic-content tbody tr:hover {
    background-color: #f1f1f1;
}

.dynamic-content {
    line-height: 1.6;
    color: #333;
    margin-bottom: 1px;
    overflow-x: auto;

  }

.dynamic-content h1, .dynamic-content h2, .dynamic-content h3, .dynamic-content h4, .dynamic-content h5, .dynamic-content h6 {
    color: #009879;
}

.dynamic-content p {
    margin-bottom: 16px;
}

.dynamic-content ul, .dynamic-content ol {
    margin: 16px 0;
    padding-left: 20px;
}

.dynamic-content li {
    margin-bottom: 8px;
}

.dynamic-content strong {
    font-weight: bold;
}

